import $ from 'jquery'
import jQueryBridget from 'jquery-bridget'
import Flickity from 'flickity'

Flickity.setJQuery($)
jQueryBridget('flickity', Flickity, $)

$(document).ready(function() {
  window.addEventListener('scroll', () => {
    window.dispatchEvent(new Event('resize'))
  })
  dhsv_vc_imageslider()
  setTimeout(() => {
    window.dispatchEvent(new Event('resize'))
  }, 2000)
})

function dhsv_vc_imageslider() {
  $('.dhsv_vc_imageslider').each(function() {
    var $el = $(this)
    var $slider = $el.find('.slider')
    var $items = $slider.find('.item')
    var $navigation = $el.find('.navigation')
    var $carousel = $slider.flickity({
      imagesLoaded: true,
      percentPosition: false,
      cellAlign: 'left',
      pageDots: false,
      draggable: false,
      lazyLoad: 1,
      //wrapAround: true,
      //contain: true,
    })

    var $nav = $navigation.flickity({
      cellAlign: 'left',
      rightToLeft: true,
      pageDots: false,
      //freeScroll: true,
      contain: false,
      //wrapAround: true,
      //contain: true,
    })

    var $imgs = $carousel.find('.item img')
    // get transform property
    var docStyle = document.documentElement.style
    var transformProp =
      typeof docStyle.transform == 'string' ? 'transform' : 'WebkitTransform'
    // get Flickity instance
    var flkty = $carousel.data('flickity')

    $carousel.on('scroll.flickity', function() {
      flkty.slides.forEach(function(slide, i) {
        var img = $imgs[i]
        var x = (slide.target + flkty.x) * 0
        img.style[transformProp] = 'translateX(' + x + 'px)'
      })
    })

    $carousel.on('change.flickity', function(event, index) {
      if (index == 0) {
        $el.find('span.arrows span.left').addClass('disabled')
      } else if (index == $items.length - 1) {
        $el.find('span.arrows span.right').addClass('disabled')
      } else {
        $el
          .find('span.arrows span.left, span.arrows span.right')
          .removeClass('disabled')
      }
    })

    $nav.on('scroll.flickity', function(event, progress) {
      $carousel.flickity('select', progress * $items.length)
    })

    $el.find('span.arrows span').click(function() {
      var info = $nav.data('flickity')
      if ($(this).hasClass('left')) {
        $nav.flickity('select', info.selectedIndex - 1)
      } else {
        $nav.flickity('select', info.selectedIndex + 1)
      }
    })
  })
}
