export default {
  init() {
    backToTop()
    dhsvVcSection()
    mouseScrollDown()
    showOnScrollPrepare()
    $(window).load(showOnScroll)
    window.addEventListener('scroll', throttle(showOnScroll, 50))
    init_vcTtaAccordion()
    init_vcTtaTabs()
  },
  finalize() {
    elementParallax()
  },
}

var $window = $(window)

function elementParallax() {
  var windowHeight = $(window).height()
  $('.parallax-element').each(function() {
    var $element = $(this)
    var $inner = $element.find('.parallaxinner')
    var speed = $element.attr('data-parallax-speed')
    var elementHeight = $element.outerHeight()

    $(window).scroll(
      throttle(function() {
        var elementTop = $element.offset().top
        var output = elementTop + elementHeight / 2 - windowHeight / 2
        var scrollTop = $(window).scrollTop()
        output = scrollTop !== 0 ? output - scrollTop : output
        output = output / speed
        $inner.css('transform', 'translateY(' + output + 'px)')
      }, 30)
    )
  })
}

function showOnScroll() {
  var scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  var winH = $window.height()
  var winStart = winH / 6 * 5

  $('.show-on-scroll').each(function() {
    var offset = $(this).offset()

    if (scrollTop > offset.top - winStart) {
      $(this).addClass('in-screen')
    } else {
      $(this).removeClass('in-screen')
    }
  })
}

function showOnScrollPrepare() {
  $('.show-on-scroll').each(function() {
    //Image Slider (Content)
    var $imageSlider = $(this).find('.dhsv_vc_imageslider_content')
    if ($imageSlider) {
      $imageSlider.each(function() {
        var i = 0
        $(this)
          .find('.item .inner')
          .each(function() {
            $(this).css('transition-delay', i / 5 + 's')
            i++
          })
      })
    }
  })
}

function throttle(fn, wait) {
  var time = Date.now()
  return function() {
    if (time + wait - Date.now() < 0) {
      fn()
      time = Date.now()
    }
  }
}

function backToTop() {
  $('.backtotop').click(function() {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      1000
    )
  })
}

function mouseScrollDown() {
  $('.mouse').click(function() {
    var scrollTop = $(window).scrollTop()
    var winH = $(window).height()
    $('html, body').animate(
      {
        scrollTop: scrollTop + winH,
      },
      1000
    )
  })
}

function dhsvVcSection() {
  $('.dhsv_line').each(function() {
    var $el = $(this)
    $el.append('<div class="line"></div>')
  })

  $('.dhsv_mouse').each(function() {
    var $el = $(this)
    $el.find('.line').css('bottom', '90px')
    $el.append('<div class="mouse"></div>')
  })
}

function init_vcTtaAccordion() {
  let lastActive = $(
    '.vc_tta-container .vc_tta-accordion .vc_tta-panels-container .vc_tta-panel.vc_active .vc_tta-panel-heading'
  )
  lastActive
    .parent()
    .find('.vc_tta-panel-body')
    .show()

  $(
    '.vc_tta-container .vc_tta-accordion .vc_tta-panels-container .vc_tta-panel-heading'
  ).on('click tap', function() {
    const e = $(this)
    const parent = e.parent()
    const content = e.next()

    if (parent.hasClass('vc_active')) {
      content.slideUp()
      parent.removeClass('vc_active')
      lastActive = $(undefined)
    } else {
      lastActive.click()
      content.slideDown()
      parent.addClass('vc_active')
      lastActive = e
    }
  })
}

function init_vcTtaTabs() {
  const $tabs = $(
    '.vc_tta-container .vc_tta-tabs .vc_tta-tabs-container .vc_tta-tabs-list .vc_tta-tab'
  )
  const $panels = $(
    '.vc_tta-container .vc_tta-tabs .vc_tta-panels-container .vc_tta-panels .vc_tta-panel:not(.vc_active)'
  )
  $panels.hide()
  $tabs.on('click tap', function() {
    const e = $(this)

    $('body, html').animate(
      {
        scrollTop: e.offset().top - 200,
      },
      500
    )
    const ulparent = $(this).parent()
    const parent = e.parents('.vc_tta-tabs')
    const lastActive = parent.find(
      '.vc_tta-tabs-container .vc_tta-tabs-list .vc_tta-tab.vc_active'
    )

    if (!e.hasClass('vc_active')) {
      lastActive.removeClass('vc_active')
      parent
        .find(
          '.vc_tta-panels-container .vc_tta-panels .vc_tta-panel[data-index="' +
            lastActive.data('index') +
            '"]'
        )
        .removeClass('vc_active')
        .slideUp(500)
      e.addClass('vc_active')
      parent
        .find(
          '.vc_tta-panels-container .vc_tta-panels .vc_tta-panel[data-index="' +
            e.data('index') +
            '"]'
        )
        .addClass('vc_active')
        .slideDown(500)
    }

    ulparent.scrollLeft(ulparent.scrollLeft() + e.position().left)
  })

  $(window).scroll(function() {
    const scrollTop = $(window).scrollTop()
    $('.vc_tta-tabs').each(function() {
      var eTop = $(this).offset().top
      var eHeight = $(this).height()

      if (scrollTop > eTop - 50 && scrollTop < eTop + eHeight) {
        $(this)
          .find('.vc_tta-tabs-container')
          .addClass('sticky')
      } else {
        $(this)
          .find('.vc_tta-tabs-container')
          .removeClass('sticky')
      }
    })
  })
}
