import React from 'react'
import PropTypes from 'prop-types'
import slugify from 'slugify'
import './style.scss'

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: 0,
      change: false,
      parentTitle: '',
      parentLink: '',
      parents: [],
      currentMenu: this.props.menuItems,
      hover: false,
      imgWidth: 0,
    }
    this.clickItem = this.clickItem.bind(this)
    this.clickBack = this.clickBack.bind(this)
    this.resizeImgSize = this.resizeImgSize.bind(this)
    this.imgWrapp = React.createRef()
  }

  componentDidMount() {
    if (!this.props.isMobile) {
      this.resizeImgSize()
      window.addEventListener('resize', this.resizeImgSize)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeImgSize)
  }

  resizeImgSize() {
    this.setState({
      imgWidth: this.imgWrapp.current.offsetWidth,
    })
  }

  getMenu(parents = this.state.parents) {
    const { menuItems } = this.props

    if (parents.length > 0) {
      let setMenu = menuItems
      let titles = []
      let links = []
      for (let i = 0; i < parents.length; i++) {
        titles = [
          ...titles,
          setMenu.find(el => el.post_id === parents[i]).title,
        ]
        links = [
          ...links,
          setMenu.find(el => el.post_id === parents[i]).permalink,
        ]
        setMenu = setMenu.find(el => el.post_id === parents[i]).items
      }
      this.setState({
        parentTitle: titles[titles.length - 1],
        parentLink: links[links.length - 1],
        currentMenu: setMenu,
      })
    } else {
      this.setState({
        parentTitle: '',
        parentLink: '',
        currentMenu: menuItems,
      })
    }
  }

  clickItem(item) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'menuEvent-' + slugify(item.title),
    })

    if ('items' in item) {
      const newParents = [...this.state.parents, item.post_id]
      this.setState({
        change: true,
      })
      setTimeout(
        function() {
          this.setState({
            active: item.post_id,
            parents: newParents,
            change: false,
          })
          this.getMenu(newParents)
        }.bind(this),
        500
      )
    } else {
      window.location.href = item.permalink
    }
  }

  clickBack() {
    const setParents = this.state.parents.slice(0, -1)
    this.setState({
      change: true,
    })
    setTimeout(
      function() {
        this.setState({
          active: setParents[setParents.length - 1],
          parents: setParents,
          change: false,
        })
        this.getMenu(setParents)
      }.bind(this),
      500
    )
  }

  onItem(item) {
    this.setState({
      hover: item.post_id,
    })
  }

  offItem() {
    this.setState({
      hover: false,
    })
  }

  render() {
    const {
      active,
      parentTitle,
      parentLink,
      currentMenu,
      change,
      imgWidth,
      hover,
    } = this.state
    const { activeMenu, subMenuItems, meta, isMobile } = this.props

    return (
      <div
        className={'dhsv-mobile-menu' + activeMenu + (change ? ' changed' : '')}
        data-active={active}
      >
        <div className="container">
          <div className="row menurow">
            <div className="col">
              {currentMenu[0].menu_item_parent !== 0 ? (
                <div onClick={this.clickBack} className="title">
                  <button>
                    <i className="icon ion-ios-arrow-thin-left" />
                  </button>
                  <span>Zurück</span>
                </div>
              ) : null}
              <ul className={'menu'}>
                {currentMenu[0].menu_item_parent !== 0 ? (
                  <li className="overview">
                    <a href={parentLink}>{parentTitle}</a>
                  </li>
                ) : null}
                {currentMenu.map((menuItem, index) => (
                  <li
                    key={index}
                    className={menuItem.items ? 'has-children' : null}
                    onClick={() => this.clickItem(menuItem)}
                    onMouseEnter={() => this.onItem(menuItem)}
                    onMouseLeave={() => this.offItem(menuItem)}
                    style={{
                      animationDelay: 0.1 + index * 0.1 + 's',
                    }}
                  >
                    <span>{menuItem.title ? menuItem.title : null}</span>
                  </li>
                ))}
              </ul>
              <ul className="submenu">
                {subMenuItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <li className={item.classes.join(' ')}>
                      <a href={item.permalink}>{item.title}</a>
                    </li>
                    {item.classes.includes('break') ? <br /> : null}
                  </React.Fragment>
                ))}
              </ul>
            </div>
            {!isMobile ? (
              <div className="col">
                <div className="imagewrapper" ref={this.imgWrapp}>
                  <div
                    className="image"
                    style={{
                      backgroundImage:
                        'url(' + this.props.placeholderImage + ')',
                    }}
                  />
                  {currentMenu.map((menuItem, index) => (
                    <div
                      key={index}
                      className={
                        'image' + (hover == menuItem.post_id ? ' hover' : '')
                      }
                    >
                      <div
                        className="innerimage"
                        style={{
                          backgroundImage: 'url(' + menuItem.image + ')',
                          width: imgWidth,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          {!isMobile ? (
            <div className="row metarow">
              <div
                className="col"
                dangerouslySetInnerHTML={{ __html: meta.address }}
              />
              <div
                className="col"
                dangerouslySetInnerHTML={{ __html: meta.opentime }}
              />
              <div
                className="col"
                dangerouslySetInnerHTML={{ __html: meta.contact }}
              />
              <div
                className="col"
                dangerouslySetInnerHTML={{ __html: meta.social }}
              />
            </div>
          ) : null}
        </div>
      </div>
    )
  }
}

MobileMenu.propTypes = {
  isMobile: PropTypes.bool,
  menuItems: PropTypes.array,
  meta: PropTypes.object,
  placeholderImage: PropTypes.string,
  subMenuItems: PropTypes.array,
  activeMenu: PropTypes.string,
}

export default MobileMenu
