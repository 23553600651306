import $ from 'jquery'

$(window).scroll(dhsv_vc_page_header_scroll)

function dhsv_vc_page_header_scroll() {
  const scrollTop = $(window).scrollTop()
  $('.dhsv_vc_page_header').each(function() {
    const $parallax = $(this).find('.parallax')

    $parallax.css({
      transform: 'translateY(' + scrollTop / 3 + 'px)',
    })
  })
}
