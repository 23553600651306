import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_layer_slider()
  dhsv_vc_layer_slider_mouse()
})

function dhsv_vc_layer_slider() {
  $('.dhsv_vc_layer_slider').each(function() {
    var $el = $(this)
    var $slider = $el.find('.slider')
    //var $items = $slider.find('.item')

    var $carousel = $slider.flickity({
      imagesLoaded: true,
      percentPosition: false,
      cellAlign: 'left',
      pageDots: true,
      lazyLoad: 1,
      //draggable: false,
      //wrapAround: true,
      //contain: true,
    })

    var speeds = [-0.6, -0.5, -0.4, -1, -0.7]

    var $imgs = $carousel.find('.item img')
    // get transform property
    var docStyle = document.documentElement.style
    var transformProp =
      typeof docStyle.transform == 'string' ? 'transform' : 'WebkitTransform'
    // get Flickity instance
    var flkty = $carousel.data('flickity')

    //console.log($imgs) //eslint-disable-line

    $carousel.on('scroll.flickity', function() {
      var int = 0
      flkty.slides.forEach(function(slide) {
        $.each(speeds, function(k, i) {
          //console.log(int) //eslint-disable-line
          var img = $imgs[int]
          var x = (slide.target + flkty.x) * i
          img.style[transformProp] = 'translateX(' + x + 'px)'
          int++
        })
      })
    })
  })
}

function dhsv_vc_layer_slider_mouse() {
  $('body').mousemove(
    throttle(function() {
      $('.dhsv_vc_layer_slider').each(function() {
        var position = $(this).position()
        var transform = [
          event.pageY - position.top,
          event.pageX - position.left,
        ]
        $(this)
          .find('.img1')
          .css({
            transform:
              'translate(' +
              transform[1] / 400 +
              'px, ' +
              transform[0] / -400 +
              'px)',
          })
        $(this)
          .find('.img2')
          .css({
            transform:
              'translate(' +
              transform[1] / -200 +
              'px, ' +
              transform[0] / 200 +
              'px)',
          })
        $(this)
          .find('.img3')
          .css({
            transform:
              'translate(' +
              transform[1] / 200 +
              'px, ' +
              transform[0] / -200 +
              'px)',
          })
        $(this)
          .find('.img4')
          .css({
            transform:
              'translate(' +
              transform[1] / -400 +
              'px, ' +
              transform[0] / 400 +
              'px)',
          })
        $(this)
          .find('.img5')
          .css({
            transform:
              'translate(' +
              transform[1] / 500 +
              'px, ' +
              transform[0] / -500 +
              'px)',
          })
      })
    }, 10)
  )
}

function throttle(fn, wait) {
  var time = Date.now()
  return function() {
    if (time + wait - Date.now() < 0) {
      fn()
      time = Date.now()
    }
  }
}
