import $ from 'jquery'

$(window).scroll(dhsv_vc_image_line)
$(window).load(dhsv_vc_image_line)

function dhsv_vc_image_line() {
  const w = $(window)
  const scrollTop = w.scrollTop()
  const winH = w.height()
  $('.dhsv_vc_image_line').each(function() {
    const $line = $(this)
    const $imgwrapper = $line.find('.imgwrapper')
    const top = $line.offset().top
    const height = $line.height() + 200
    const centerPos = top + height / 2
    $imgwrapper.each(function() {
      const rand = $(this).attr('data-rand')
      const cssVal = scrollTop - centerPos + winH / 2
      $(this).css({
        transform: 'translateY(' + cssVal / rand + 'px)',
      })
    })
  })
}
