import React from 'react'
import PropTypes from 'prop-types'
import MobileMenu from './../MobileMenu'
import './style.scss'

class MobileHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sticky: false,
      toggled: false,
      showMenu: false,
    }
    this.handleClick = this.handleClick.bind(this)
    this.scrollHeader = this.scrollHeader.bind(this)
  }

  componentDidMount() {
    document.addEventListener('scroll', this.scrollHeader)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHeader)
  }

  scrollHeader() {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop

    this.setState({
      sticky: scrollTop > 300,
    })
  }

  handleClick() {
    const { toggled, showMenu } = this.state
    if (toggled) {
      this.setState({
        toggled: !toggled,
      })
      setTimeout(
        function() {
          this.setState({
            showMenu: !showMenu,
          })
        }.bind(this),
        500
      )
    } else {
      this.setState({
        showMenu: !showMenu,
      })
      setTimeout(
        function() {
          this.setState({
            toggled: !toggled,
          })
        }.bind(this),
        0
      )
    }
  }

  render() {
    const { menuItems, subMenuItems, globals, isMobile } = this.props
    const { toggled, showMenu, sticky } = this.state
    return (
      <div className={'dhsv_header'}>
        {isMobile ? (
          <div className={'openings' + (showMenu || sticky ? ' scroll' : '')}>
            {globals.meta.number ? (
              <a href={'tel:' + globals.meta.number}>
                <i className="icon ion-android-call" />
              </a>
            ) : null}
            <span dangerouslySetInnerHTML={{ __html: globals.meta.opentime }} />
            {globals.meta.linkMaps ? (
              <a href={globals.meta.linkMaps} target="_blank">
                <i className="icon ion-android-pin" />
              </a>
            ) : null}
          </div>
        ) : null}
        <div className={'header' + (showMenu || sticky ? ' scroll' : '')}>
          {globals.langs ? (
            <div className="lang">
              {globals.langs.map((lang, index) => (
                <a
                  key={index}
                  href={lang.url != '%lang' ? lang.url : '/'}
                  className={lang.active == 1 ? 'active' : null}
                >
                  {lang.code}
                </a>
              ))}
            </div>
          ) : null}
          <div className="brand">
            <a href={globals.logo_link}>
              <img src={globals.logo_url} />
            </a>
          </div>
          <div
            className={'menutoggle' + (toggled ? ' open-toggle' : '')}
            onClick={this.handleClick}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        {showMenu ? (
          <MobileMenu
            menuItems={menuItems}
            placeholderImage={globals.menu_placeholder}
            subMenuItems={subMenuItems}
            meta={globals.meta}
            activeMenu={toggled ? ' open-menu' : ''}
          />
        ) : null}
      </div>
    )
  }
}

MobileHeader.propTypes = {
  isMobile: PropTypes.bool,
  globals: PropTypes.object,
  menuItems: PropTypes.array,
  subMenuItems: PropTypes.array,
}

export default MobileHeader
