import $ from 'jquery'

$(window).load(function() {
  dhsv_vc_videostage()
})

$(window).scroll(function() {
  $('.dhsv_vc_videostage').each(function() {
    //var video = $(this).find('video')
    //var scrollTop = $(window).scrollTop()
    //video.css('top', scrollTop)
  })
})

function dhsv_vc_videostage() {
  $('.dhsv_vc_videostage').each(function() {
    // select video element
    //var vid = document.getElementById('videostage')
    var winH = $(window).height()
    var vid = $(this).find('video')[0] // jquery option

    // pause video on load
    vid.pause()

    // alternative & optimized implementation  thanks to http://codepen.io/daveroma/

    window.onscroll = function() {
      //console.log(vid.currentTime) //eslint-disable-line
      vid.currentTime = window.pageYOffset / (winH * 2 / vid.duration)
    }
  })
}
