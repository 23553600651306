import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_parallax_images_mouse()
})

function dhsv_vc_parallax_images_mouse() {
  $('.dhsv_vc_parallax_images').each(function() {
    var el = $(this)
    var $images = $(this).find('.parallax-element')
    var speeds = [100, 300]
    var speedarray = []
    var intarray = []
    $images.each(function() {
      var range = Math.floor(Math.random() * speeds[1]) + speeds[0]
      var int = Math.floor(Math.random() * 2) + 0
      speedarray.push(range)
      intarray.push(int)
    })

    $('body').mousemove(
      throttle(function() {
        var position = el.position()
        var transform = [
          event.pageY - position.top,
          event.pageX - position.left,
        ]
        el.find('.bg-plate').css({
          transform:
            'translate(' +
            transform[1] / 300 +
            'px, ' +
            transform[0] / 300 +
            'px)',
        })

        $images.each(function(i) {
          var val = intarray[i] == 1 ? -speedarray[i] : speedarray[i]
          $(this).css({
            transform:
              'translate(' +
              transform[1] / val +
              'px, ' +
              transform[0] / val +
              'px)',
          })
        })
      }, 10)
    )
  })
}

function throttle(fn, wait) {
  var time = Date.now()
  return function() {
    if (time + wait - Date.now() < 0) {
      fn()
      time = Date.now()
    }
  }
}
