// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/_polyfill.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/arrangements/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/googlemap/main.js'; import '../vc_elements/history/main.js'; import '../vc_elements/image_line/main.js'; import '../vc_elements/imageslider_content/main.js'; import '../vc_elements/imageslider/main.js'; import '../vc_elements/layer_slider/main.js'; import '../vc_elements/map/main.js'; import '../vc_elements/page_header/main.js'; import '../vc_elements/parallax_images/main.js'; import '../vc_elements/template_element/main.js'; import '../vc_elements/trenner/main.js'; import '../vc_elements/videostage/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
