import React from 'react'
import PropTypes from 'prop-types'
/* Ref: https://github.com/google-map-react/google-map-react/blob/master/API.md */
import GoogleMapReact from 'google-map-react'
import Marker from './Marker'

let timeOut = undefined

class GoogleMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showGoogleMap: false,
    }
    this.checkBorlabsConsent = this.checkBorlabsConsent.bind(this)

    const { data: { marker, options, key } } = props
    const styles = options.options_styles
      ? require('./Styles/' + options.options_styles)
      : []
    const behavior = options.options_behavior ? options.options_behavior : ''
    const controls = options.options_controls ? options.options_controls : ''

    this.MAP = {
      defaultZoom: options.options_defaultzoom
        ? Number(options.options_defaultzoom)
        : 16,
      defaultCenter: marker
        ? {
            lat: centerItems('lat', marker),
            lng: centerItems('lng', marker),
          }
        : {
            lat: 49.86854,
            lng: 8.92726,
          },
      options: {
        styles: styles,
        maxZoom: Number(options.options_maxzoom),
        minZoom: Number(options.options_minzoom),
        minZoomOverride: options.options_minzoom ? true : false,
        /* MAP BEHAVIOR */
        scrollwheel: behavior.includes('scrollwheel'),
        draggable: behavior.includes('draggable'),
        keyboardShortcuts: behavior.includes('keyboardShortcuts'),
        disableDoubleClickZoom: behavior.includes('disableDoubleClickZoom'),
        /* CONTROLS */
        zoomControl: controls.includes('zoomControl'),
        panControl: controls.includes('panControl'),
        mapTypeControl: controls.includes('mapTypeControl'),
        scaleControl: controls.includes('scaleControl'),
        streetViewControl: controls.includes('streetViewControl'),
        rotateControl: controls.includes('rotateControl'),
        fullscreenControl: controls.includes('fullscreenControl'),
      },
      key: key,
    }
  }

  componentDidMount() {
    this.checkBorlabsConsent()
  }

  checkBorlabsConsent() {
    const googleMapsAllowed =
      typeof window.BorlabsCookie === 'object' &&
      window.BorlabsCookie.checkCookieConsent('googlemaps') &&
      !/Chrome-Lighthouse/.test(navigator.userAgent) // don't render map in lighthouse test
    if (googleMapsAllowed != undefined) {
      this.setState({ showGoogleMap: googleMapsAllowed })
      if (googleMapsAllowed) {
        clearTimeout(timeOut)
      } else {
        timeOut = setTimeout(this.checkBorlabsConsent, 1000)
      }
    }
    // eslint-disable-next-line no-console
    console.log(googleMapsAllowed)
  }

  render() {
    const { MAP } = this
    const { data: { marker } } = this.props
    const { showGoogleMap } = this.state

    return (
      <div className="mapwrapper" style={{ height: 500 }}>
        {showGoogleMap ? (
          <GoogleMapReact
            ref={e => {
              this.map = e
            }}
            defaultZoom={MAP.defaultZoom}
            defaultCenter={MAP.defaultCenter}
            options={MAP.options}
            onChange={this.handleMapChange}
            yesIWantToUseGoogleMapApiInternals
            bootstrapURLKeys={{ key: MAP.key }}
          >
            {marker
              ? marker.map((place, key) => (
                  <Marker key={key} lat={place.lat} lng={place.lng} />
                ))
              : null}
          </GoogleMapReact>
        ) : (
          <div className="cookie-googlemaps">
            <h2>Stimme den Cookies zu, um Google Maps zu laden.</h2>
            <a href="#" className="borlabs-cookie-preference btn btn-primary">
              zu den Cookie Einstellungen
            </a>
          </div>
        )}
      </div>
    )
  }
}

function centerItems(index, array) {
  const sum = array
    .map(item => Number(item[index]))
    .reduce((pre, cur) => (cur += pre))
  return Number(sum / array.length)
}

GoogleMap.propTypes = {
  data: PropTypes.object.isRequired,
  overlay: PropTypes.object,
}

export default GoogleMap
