import $ from 'jquery'
import lottie from 'lottie-web'
import data from './data'

$(document).ready(function() {
  dhsv_vc_history()
})

const animLength = 1800
const segment = [0, animLength]
let startFrame = animLength
let endFrame = 0

function dhsv_vc_history() {
  var divContainer = document.getElementById('storyline-animation')
  if (divContainer) {
    const animation = lottie.loadAnimation({
      container: divContainer,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: data,
      rendererSettings: {
        preserveAspectRatio: 'none',
      },
    })

    animation.setSpeed(3604)

    createAnimSegment(animation, divContainer)
    imageTrigger()

    document.addEventListener('scroll', () => {
      createAnimSegment(animation, divContainer)
      imageTrigger()
    })

    window.addEventListener('resize', () => {
      createAnimSegment(animation, divContainer)
    })
  }
}

function createAnimSegment(animation, divContainer) {
  const divHeight = divContainer.offsetHeight,
    divPositionTop = offset(divContainer)
  startFrame = endFrame
  endFrame =
    animLength -
    Math.floor(
      (window.pageYOffset - divPositionTop['top']) *
        (animLength / (divHeight - window.innerHeight))
    )

  if (endFrame >= animLength) {
    endFrame = animLength
    animation.setSpeed(3604)
    animation.playSegments([animLength, animLength], true)
    animation.addEventListener('complete', function() {
      animation.setSpeed(5)
    })
  }

  if (endFrame <= 0) {
    endFrame = 0
  }

  segment[0] = startFrame
  segment[1] = endFrame

  if (startFrame !== endFrame) {
    animation.playSegments(segment, true)
  }
}

function imageTrigger() {
  const scrollPos = document.documentElement.scrollTop,
    scrollPos2 = window.scrollY,
    screenHeight = document.documentElement.clientHeight
  let imagePos

  $('[data-dir]').each(function() {
    imagePos = $(this).offset()

    if (
      imagePos.top - screenHeight / 3 * 2 <= scrollPos ||
      imagePos.top - screenHeight / 3 * 2 <= scrollPos2
    ) {
      $(this).addClass('active')
    } else {
      $(this).removeClass('active')
    }
  })
}

function offset(el) {
  const rect = el.getBoundingClientRect(),
    scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop }
}
