import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_map()
})

function dhsv_vc_map() {
  $('.dhsv_vc_map').each(function() {
    const $items = $(this).find('.item')
    const $modal = $(this).find('.modal')
    const data = JSON.parse($(this).attr('data-hotspots'))
    //const $closebutton = $modal.find('.close')
    // console.log(JSON.parse(data)) //eslint-disable-line
    //console.log('HERE' + $closebutton) //eslint-disable-line
    $items.click(function() {
      if ($(this).hasClass('active')) {
        $(this).removeClass('active')
        $modal.addClass('hidden')
      } else {
        $items.removeClass('active')
        $(this).addClass('active')
        const id = $(this).attr('data-id')
        writeModal($modal, data[id])
      }
    })

    $modal.on('click', '.close', function() {
      if ($items.hasClass('active')) {
        $items.removeClass('active')
        $modal.addClass('hidden')
      }
    })
  })
}

function writeModal($el, data) {
  let html = `
  <div class="close">
      <i class="ion-close"></i>
    </div>
    <div class="title">
      <h2>${data.title}</h2>
    </div>
    `

  if (data.image) {
    html += `
    <div class="image" style="background-image: url(${data.image})">
  </div>    
    `
  }

  html += `
    <div class="text">
      <p>${data.text}</p>
    </div>
    `

  if (data.link.url) {
    html += `
     <div class="link">
      <a href="${data.link.url}" target="_blank">${data.link.title}</a>
    </div>
    `
  }

  $el.html(html)

  $el.removeClass('hidden')
}
